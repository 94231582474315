"use client";
import SearchBar from "@/components/ui/Search/SearchBar";

import { useLocalStorageSafe } from "@/hooks/useLocalStorageSafe";
import {
  AUTH_LINKS,
  generateNavLinks,
  TOPICS_LINKS,
} from "@/lib/navigation-links";
// import { User } from "@/services/api/queries";
import { User } from "@/services/api/queries";
import { useAuth } from "@/services/api/useAuth";
import { logout, setAuthToken } from "@/services/redux/authSlice";
import { useAppDispatch } from "@/services/redux/hooks";
import { TokenStore } from "@/types/auth";
import { cn } from "@/utils/helpers/utils";
import { Popover, Transition } from "@headlessui/react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { CSSProperties, Fragment, useEffect, useState } from "react";
import { CiLogout } from "react-icons/ci";
import { IoChevronDown } from "react-icons/io5";
import { match } from "ts-pattern";
import { buttonVariants } from "../Button";
import OptimizedImage from "../Image/OptimizedImage";
import FlyoutMenu from "./flyoutMenu";

function DesktopNavBar({ token, latestVideoSlug }: any) {
  const navigationLinks = generateNavLinks(latestVideoSlug);
  const [user] = useLocalStorageSafe<User>("user", null);
  const [tokens] = useLocalStorageSafe<TokenStore>("tokens", {
    training_session: { token: "", exp: new Date() },
    jobs_session: { token: "", exp: new Date() },
  });

  // }
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (token) {
      dispatch(setAuthToken(token.value));
    }
  }, []);

  const [offsetStyle, setOffsetStyle] = useState<CSSProperties>({
    top: "0px",
    position: "absolute", // Change initial position to absolute
    width: "100%",
    zIndex: 100,
  });
  const [_, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 160) {
        setOffsetStyle({
          top: "0px",
          position: "fixed",
          boxShadow: "0 0px 8px 0px #78788c33",
          zIndex: 100,
        });
        setIsScrolled(true);
      } else {
        setOffsetStyle({
          top: "0px",
          position: "absolute", // Keep it absolute when not fixed
          width: "100%",
          zIndex: 100,
        });
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div
        className=" w-full bg-white transition-all duration-300"
        style={{
          // ...offsetStyle,
          position: "relative",

          // boxShadow: "0 16px 8px 0px #78788c33",
          zIndex: 100,
        }}
      >
        <nav
          className="w-full bg-white  px-4 py-6"
          style={{
            ...offsetStyle,

            zIndex: 100,
          }}
        >
          <div className="   mx-auto  flex max-w-[75rem] ">
            <div className=" flex flex-1  items-center justify-between gap-4 text-[#0a0a0a]">
              {/* <div className="flex items-center gap-10"> */}
              <Link
                aria-label="Navigate to home page"
                href={"/"}
                className="relative h-[50px] w-[180px]"
              >
                <OptimizedImage
                  src={`/_logos/logo-restaurant-association-horizontal-color.webp`}
                  alt="logo"
                  className="h-auto w-full"
                  priority
                  // fill
                  height={68}
                  width={256}
                  // width={180}
                  // height={50}
                  preload
                  cloudfront={true}
                />
              </Link>

              <ol className=" flex gap-4  ">
                {navigationLinks.map((link) => {
                  const linkElement = match(link.title)
                    .with("Training", () => (
                      <li key={`${link.title}_desktop_nav`}>
                        <Link
                          aria-label={`Navigate to ${link.title} page`}
                          href={`${link.href}?session=${link.title === "Training" ? tokens.training_session.token : tokens.jobs_session.token}`}
                          className="  font-normal active:text-brand-black"
                        >
                          {link.title}
                        </Link>
                      </li>
                    ))
                    .with("Jobs", () => (
                      <li key={`${link.title}_desktop_nav`}>
                        <Link
                          aria-label={`Navigate to ${link.title} page`}
                          href={`${link.href}?session=${link.title === "Training" ? tokens.training_session.token : tokens.jobs_session.token}`}
                          className="  font-normal active:text-brand-black"
                        >
                          {link.title}
                        </Link>
                      </li>
                    ))
                    .with("Topics", () => null)
                    .with("Resources", () => (
                      <li key={`${link.title}_desktop_nav`}>
                        <FlyoutMenu
                          links={link?.content ?? []}
                          title={link.title}
                          gridStyle="justify-center gap-8 "
                          itemStyle="grow-0"
                        />
                      </li>
                    ))
                    .otherwise(() => (
                      <li key={`${link.title}_desktop_nav`}>
                        <Link
                          aria-label={`Navigate to ${link.title} page`}
                          href={link.href ?? ""}
                          className="font-normal active:text-brand-black"
                        >
                          {link.title}
                        </Link>
                      </li>
                    ));

                  return linkElement;
                })}
              </ol>
              {/* </div> */}

              <div className="   relative flex min-w-[180px] items-center justify-end gap-2">
                <SearchBar toggle={() => {}} />
                {token && user ? (
                  <UserTile user={user} />
                ) : (
                  <Link
                    href={AUTH_LINKS.signup.href}
                    className={buttonVariants({
                      size: "lg",
                      className: "",
                      variant: "gradient",
                    })}
                  >
                    {/* Login */}
                    Sign Up
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>

        <div className=" relative top-24 w-full bg-gray-200 py-1">
          <div className="   mx-auto  flex max-w-[75rem] justify-center gap-4">
            {TOPICS_LINKS.map((topic) => (
              <Link
                href={topic.href}
                key={topic.title}
                className={buttonVariants({
                  size: "lg",
                  className: "!font-normal !text-black",
                  variant: "link",
                })}
              >
                {topic.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default DesktopNavBar;

function UserTile({ user }: { user: User }) {
  const { logout: fetchLogout } = useAuth();
  const router = useRouter();
  const dispatch = useAppDispatch();

  return (
    <Popover className="  overflow-hidden">
      <div className="bg-transparent bg-white">
        <div className="mx-auto max-w-7xl ">
          <Popover.Button className="relative inline-flex items-center gap-x-1  text-base  font-semibold text-gray-900 active:text-brand-black">
            {`${user?.firstName ?? ""} ${user?.lastName ?? ""}` || "Logout"}
            {/* <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> */}
            <IoChevronDown
              className="transition-transform ui-open:rotate-90 ui-open:transform"
              aria-hidden="true"
            />
          </Popover.Button>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={cn("  absolute left-0 right-0 top-0   bg-white pt-16 ")}
          style={{
            zIndex: -100,
          }}
        >
          {/* <Popover.Overlay className="fixed inset-0" /> */}

          <div
            className={cn(
              " border-top-0  mx-auto flex max-w-7xl items-center  justify-center rounded-lg px-4 py-8 shadow-lg  ",
            )}
            style={{
              boxShadow: "inset 10px 0 0 calc(1px + 0) rgb(17 24 39 / 0.05)",
            }}
          >
            <div
              className={cn(
                ` w-1/7   group  relative flex grow items-center justify-between gap-6 rounded-lg  bg-white px-2 text-center text-sm leading-6  hover:bg-gray-50 sm:flex-row`,

                // links.length < 4 && index === 0 ? " lg:col-start-2" : "",
                // itemStyle,
              )}
              onClick={async () => {
                dispatch(logout());
                await fetchLogout();

                router.refresh();
              }}
            >
              <div>
                <a className=" font-semibold text-gray-900 group-hover:bg-white">
                  {/* {item?.title} */}
                  Logout
                  <span className="absolute inset-0" />
                </a>
                {/* <p className="mt-1 text-gray-600">{item?.description}</p> */}
              </div>
              <div className="flex flex-none items-center justify-center rounded-lg bg-gray-50 p-2 group-hover:bg-white ">
                <CiLogout
                  className="h-6 w-6 text-gray-600 group-hover:text-brandOrange"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
