import { ContentNavItem, NavItem } from "@/types";
import { BiCategoryAlt } from "react-icons/bi";
import { FaLaptopCode, FaRegNewspaper } from "react-icons/fa";
import { GrCertificate, GrResources } from "react-icons/gr";
import { HiOutlineTemplate } from "react-icons/hi";
import { IoBusinessOutline, IoLibraryOutline } from "react-icons/io5";
import { LuClipboardList } from "react-icons/lu";
import {
  MdEvent,
  MdOndemandVideo,
  MdOutlineAutoGraph,
  MdOutlineSchool,
  MdOutlineWorkOutline,
} from "react-icons/md";
import { TbToolsKitchen3, TbTruckDelivery } from "react-icons/tb";

const resourceLinks: ContentNavItem[] = [
  {
    title: "Library",
    href: "/library/",
    icon: IoLibraryOutline,
    description: "Library Resources for the Restaurant industry",
  },
  {
    title: "Templates",
    href: "/templates/",
    icon: HiOutlineTemplate,
    description:
      "Ready to use templates and guides for the Restaurant Industry",
  },
  {
    title: "Food Handler Certification",
    href: "https://foodhandler.restaurantassociation.com",

    icon: GrCertificate,
    description: "",
  },
];
const content: ContentNavItem[] = [
  {
    title: "Tech",
    href: "/topics/tech/",
    icon: FaLaptopCode,
    description:
      "Non esse est duis cillum aliqua. Adipisicing laborum laborum incididunt aute.",
  },
  {
    title: "Marketing Ideas",
    href: "/topics/marketing-ideas/",
    icon: MdOutlineAutoGraph,
    description:
      "Deserunt eu pariatur magna dolore ea. Adipisicing laborum laborum incididunt aute.",
  },
  {
    title: "Operations",
    href: "/topics/operations/",
    icon: LuClipboardList,
    description:
      "Adipisicing laborum laborum incididunt aute. Adipisicing laborum laborum incididunt aute.",
  },
  {
    title: "Menu Development",
    href: "/topics/menu-development/",
    icon: TbToolsKitchen3,
    description:
      "Irure adipisicing nisi duis aliqua nisi sit commodo exercitation ullamco est ipsum deserunt voluptate.",
  },
  {
    title: "Delivery",
    href: "/topics/delivery/",
    icon: TbTruckDelivery,
    description:
      "Ipsum ex Lorem occaecat exercitation nostrud magna consectetur proident.",
  },
  {
    title: "Franchising",
    href: "/topics/franchising/",
    icon: IoBusinessOutline,
    description:
      "Officia aute sit proident dolor pariatur pariatur exercitation mollit qui id.",
  },
  {
    title: "News",
    href: "/topics/news/",
    icon: FaRegNewspaper,
    description: "Culpa sit ex non ex nostrud minim voluptate.",
  },
];

export const generateNavLinks = (latestVideoSlug: string) => {
  const navigationLinks: NavItem[] = [
    {
      title: "Topics",
      content,
      icon: BiCategoryAlt,
    },
    {
      title: "Shows",
      href: `/shows/`,
      icon: MdOndemandVideo,
    },
    {
      title: "Training",
      href: "https://training.restaurantassociation.com",
      icon: MdOutlineSchool,
    },
    {
      title: "Events",
      href: "https://webinar.restaurantassociation.com",
      icon: MdEvent,
    },
    {
      title: "Jobs",
      href: "https://jobs.restaurantassociation.com",
      icon: MdOutlineWorkOutline,
    },

    {
      title: "Resources",
      content: resourceLinks,
      icon: GrResources,
    },
  ];
  return navigationLinks;
};

export const AUTH_LINKS = {
  login: {
    title: "Login",
    href: "/unified/login/",
  },
  signup: {
    title: "Login",
    href: "/unified/signup/",
  },
  success: {
    title: "Success",
    href: "/unified/signup/success/",
  },
  member_benefits: {
    title: "Member Benefits",
    href: "/unified/signup/success/#membership-benefits",
  },
};

export const TOPICS_LINKS = [
  {
    title: "Tech",

    href: "/topics/tech/",
  },
  {
    title: "Marketing Ideas",
    href: "/topics/marketing-ideas/",
  },
  {
    title: "Operations",
    href: "/topics/operations/",
  },
  {
    title: "Menu Development",
    href: "/topics/menu-development/",
  },
  {
    title: "Delivery",
    href: "/topics/delivery/",
  },
  {
    title: "Franchising",
    href: "/topics/franchising/",
  },
  {
    title: "News",
    href: "/topics/news/",
  },
];
