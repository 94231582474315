"use client";
import Image, { ImageProps } from "next/image";
import { useState } from "react";

export interface OptimizedImageProps extends ImageProps {
  src: string;
  fallbackSrc?: string;
  placeholderSrc?: string;
  loadingComponent?: React.ReactNode;
  priority?: boolean;
  cloudfront?: boolean;
  preload?: boolean; // Preload prop
}
// const getOptimizedImageUrl = (
//   src: string,
//   cloudfront: boolean,
//   ...props: any
// ) => {
//   // Handle Cloudfront URLs
//   if (cloudfront) {
//     return `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL ?? process.env.CLOUDFRONT_URL}${src}`;
//   }

//   // Handle Unsplash URLs
//   if (src.includes("unsplash.com")) {
//     const url = new URL(src);
//     url.searchParams.set("auto", "compress,format");
//     url.searchParams.set("fit", "crop");
//     url.searchParams.set("q", "75");

//     // Add width parameter if we have it in props
//     if (props?.width) {
//       url.searchParams.set("w", props.width.toString());
//     }

//     return url.toString();
//   }

//   // Handle S3 URLs
//   if (src.includes("s3.amazonaws.com")) {
//     return src; // Already handled by Cloudfront
//   }

//   return src;
// };

const Img: React.FC<OptimizedImageProps> = ({
  src,
  alt,
  fallbackSrc = "/placeholders/default.webp",
  placeholderSrc,
  priority = false,
  cloudfront = true,
  preload = false, // Preload images if needed
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState(
    cloudfront
      ? `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL ?? process.env.CLOUDFRONT_URL}${src}`
      : src,
  );
  // const [imageSrc, setImageSrc] = useState(
  //   getOptimizedImageUrl(src, cloudfront),
  // );

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <Image
      src={hasError ? fallbackSrc : imageSrc}
      alt={alt}
      decoding="async"
      loading={priority ? "eager" : "lazy"}
      onError={() => setHasError(true)}
      {...props}
      style={{
        opacity: isLoading ? 0 : 1,
        transition: "opacity 0.5s ease-in-out",
        ...props.style,
      }}
    />
  );
};

export default Img;

// export default OptimizedImage;
