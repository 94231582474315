"use client";
import { useLocalStorageSafe } from "@/hooks/useLocalStorageSafe";
import { AUTH_LINKS, generateNavLinks } from "@/lib/navigation-links";
import { useAuth } from "@/services/api/useAuth";
import { logout, setAuthToken } from "@/services/redux/authSlice";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import { setAllVideos } from "@/services/redux/videoSlice";
import { TokenStore } from "@/types/auth";
import { Disclosure } from "@headlessui/react";
import Hamburger from "hamburger-react";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { match } from "ts-pattern";
import { buttonVariants } from "../Button";
import OptimizedImage from "../Image/OptimizedImage";
import NewsletterFull from "../Newsletter/Newsletter";
import SearchBar from "../Search/SearchBar";
import DesktopNavBar from "./desktop";

function Header({ token, videos, latestVideoSlug }: any) {
  const ref = useRef<any>(null);
  const { isAuthenticated, authToken } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      dispatch(setAuthToken(token.value));
    }
    if (videos) {
      dispatch(setAllVideos(videos));
    }
  }, [token]);

  return (
    <>
      <header className="   w-full overflow-visible border lg:mt-8" ref={ref}>
        <div className="hidden lg:block">
          {/* <ClientOnly> */}
          <DesktopNavBar
            token={authToken}
            isAuthenticated={isAuthenticated}
            latestVideoSlug={latestVideoSlug}
          />
          {/* </ClientOnly> */}
        </div>
        <div className="block lg:hidden">
          {/* <ClientOnly> */}
          <MobileNavBar latestVideoSlug={latestVideoSlug} />
          {/* </ClientOnly> */}
        </div>
      </header>
    </>
  );
}

export default Header;

function MobileNavBar({ latestVideoSlug }: { latestVideoSlug: string }) {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const [isOpen, setOpen] = useState<boolean>(false);
  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };
  const { logout: fetchLogout } = useAuth();
  const dispatch = useAppDispatch();

  return (
    <nav
      className={`  sticky top-0 z-50  h-[98px] w-full bg-white px-4 py-6`}
      style={{
        boxShadow: "0 0 8px 0 #78788c33",
        position: "fixed",
        top: 0,
      }}
    >
      <div className="flex items-center justify-between">
        <Link aria-label="Navigate to home page" href={"/"} className="">
          <OptimizedImage
            src={`/_logos/logo-restaurant-association-horizontal-color.webp`}
            alt="logo"
            className=" h-auto w-full"
            // style={{ objectFit: "contain" }}
            cloudfront={true}
            height={68}
            width={256}
            // quality={100}
          />
        </Link>
        <div className="flex items-center gap-0">
          {isAuthenticated ? (
            <button
              aria-label="Logout of Restaurant Association"
              className="btn btn-ghost btn-sm h-auto px-0 text-xs font-semibold"
              onClick={async () => {
                dispatch(logout());
                await fetchLogout();
              }}
            >
              Logout
            </button>
          ) : (
            <Link
              aria-label="Login to Restaurant Association"
              href={AUTH_LINKS.signup.href}
              // className={`btn  btn-sm h-auto px-0 text-xs font-semibold `}
              className={buttonVariants({
                size: "sm",
                className: "",
                variant: "gradient",
              })}
            >
              Sign Up
            </Link>
          )}
          <Hamburger
            toggled={isOpen}
            toggle={toggleOpen}
            size={20}
            label="mobile menu"
          />
        </div>
      </div>
      <MobileMenu
        isOpen={isOpen}
        toggle={toggleOpen}
        isAuthenticated={isAuthenticated}
        latestVideoSlug={latestVideoSlug}
      />
    </nav>
  );
}

function MobileMenu({
  isOpen,
  toggle,
  isAuthenticated,
  latestVideoSlug,
}: {
  isOpen: boolean;
  toggle: () => void;
  isAuthenticated?: boolean;
  latestVideoSlug: string;
}) {
  const [tokens] = useLocalStorageSafe<TokenStore>("tokens", {
    training_session: { token: "", exp: new Date() },
    jobs_session: { token: "", exp: new Date() },
  });
  const navigationLinks = generateNavLinks(latestVideoSlug);
  return (
    <div className="absolute left-0 right-0 top-0 z-50 bg-white">
      {/* topbar */}

      <div
        className={`${
          isOpen ? "flex" : "hidden"
        }  h-[55px] w-screen items-center justify-between bg-[#f5f5fa] py-3 pr-6`}
      >
        <Hamburger
          toggled={isOpen}
          toggle={toggle}
          size={20}
          label="mobile menu"
        />

        <SearchBar toggle={toggle} />
      </div>

      <div
        className={`${isOpen ? "flex" : "hidden"} h-screen flex-col lg:hidden`}
      >
        <ol className="flex w-full flex-col divide-y-2">
          {navigationLinks.map((link) =>
            !!link.content ? (
              <li className=" w-full" key={`${link.title}_mobile`}>
                <Disclosure>
                  <Disclosure.Button
                    className="flex w-full items-center justify-between px-6 py-[.875rem]"
                    aria-label="toggle dropdown"
                    role="menu"
                  >
                    <li className="text-md list-none   text-left font-medium leading-6 tracking-[.4px] text-[#0a0a0a]">
                      {link.title}
                    </li>
                    <FaChevronDown
                      size={"20px"}
                      color={"black"}
                      className="transition-transform duration-300 ease-in-out ui-open:rotate-180 ui-open:transform"
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className=" ">
                    <ul className="divde-[#dddee6] flex flex-col divide-y bg-[#f5f5fa]">
                      {link.content?.map((topic: any) => {
                        // let title = category.replace("-", " ");
                        // .toLocaleUpperCase()} • (${categories[category]})`;
                        return (
                          <Link
                            aria-label={`View all posts in ${topic.title}`}
                            //@ts-ignore
                            href={`${topic.href}` ?? "/"}
                            key={`${topic.title}_mobile`}
                            className="flex items-center gap-1 pl-8"
                            onClick={toggle}
                          >
                            <topic.icon
                              className="h-6 w-6 text-gray-600 group-hover:text-brandOrange"
                              aria-hidden="true"
                            />

                            <li className="text-md list-none bg-[#f5f5fa] px-6 py-[.875rem] font-medium capitalize leading-6 tracking-[.4px] text-[#323239]">
                              {topic.title}
                            </li>
                          </Link>
                        );
                      })}
                    </ul>
                  </Disclosure.Panel>
                </Disclosure>
              </li>
            ) : (
              <li
                className="text-md list-none px-6 py-[.875rem] font-medium leading-6 tracking-[.4px] text-[#0a0a0a]"
                key={`${link.title}_mobile`}
              >
                {match(link.title)
                  .with("Training", () => (
                    <Link
                      key={`${link.title}_desktop_nav`}
                      aria-label={`Navigate to ${link.title} page`}
                      href={`${link.href}?session=${link.title === "Training" ? tokens.training_session.token : tokens.jobs_session.token}`}
                      className="  font-normal active:text-brand-black"
                    >
                      {link.title}
                    </Link>
                  ))
                  .with("Jobs", () => (
                    <Link
                      key={`${link.title}_desktop_nav`}
                      aria-label={`Navigate to ${link.title} page`}
                      href={`${link.href}?session=${link.title === "Training" ? tokens.training_session.token : tokens.jobs_session.token}`}
                      className="  font-normal active:text-brand-black"
                    >
                      {link.title}
                    </Link>
                  ))
                  .otherwise(() => (
                    <Link
                      aria-label={`Navigate to ${link.title} page`}
                      href={link.href ?? "/"}
                      target={
                        link?.href?.startsWith("http") ? "_blank" : "_self"
                      }
                      onClick={toggle}
                    >
                      {link.title}
                    </Link>
                  ))}
              </li>
            ),
          )}
        </ol>
        {/* newsletter */}

        {!isAuthenticated && (
          <section
            className="border-[#f5f5fa mb-8 border-y bg-[#f5f5fa]
          py-8"
          >
            {/* Newsletter */}

            <NewsletterFull />
          </section>
        )}
      </div>
    </div>
  );
}
