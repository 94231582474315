import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

import { StaticImport } from "next/dist/shared/lib/get-img-props";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertImagePathToS3(path: string | StaticImport) {
  return `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}${path}`;
}

export function isCloudFrontUrl(url: string | undefined | null) {
  if (!url) return false;
  const cloudFrontPattern = /cloudfront\.net/;
  return cloudFrontPattern.test(url);
}
export function calculateReadingTime(articleObject: any, wordsPerMinute = 225) {
  const paragraphs = articleObject.paragraphs || [];
  const totalWords = paragraphs.reduce((total: any, paragraph: any) => {
    const text = paragraph.t_text || "";
    const words = text.split(/\s+/).length;
    return total + words;
  }, 0);
  const estimatedReadingTime = totalWords / wordsPerMinute;
  const roundedReadingTime = Math.ceil(estimatedReadingTime);
  return roundedReadingTime;
}

export const getCategoriesWithCount = (posts: any) =>
  posts.reduce((acc: any, post: any) => {
    let category = post.data.category;
    if (acc[category]) {
      acc[category]++;
    } else {
      acc[category] = 1;
    }
    return acc;
  }, {});
