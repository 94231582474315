import { useAppSelector } from "@/services/redux/hooks";
import axios from "axios";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { IoSearch } from "react-icons/io5";

interface ISearchSuggestions {
  videos: any[];
  articles: any[];
}

// const articleSearchQuery = e.params({ searchTerm: e.str }, (params) =>
//   e.select(e.Articles, ()=>{
//     filter: e.fts.search(e.Articles, params.searchTerm),
//   }),
// );

function SearchBar({ toggle }: { toggle: () => void }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const [categorizedSuggestions, setCategorizedSuggestions] =
    useState<ISearchSuggestions>({
      videos: [],
      articles: [],
    });
  const [showInput, setShowInput] = useState<boolean>(false);
  const { allVideos } = useAppSelector((state) => state.video);

  // Function to handle input change
  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setShowSuggestions(true);
      const query = e.target.value;
      setSearchQuery(query);

      // Simulate fetching suggestions from an API or database
      // Replace this with actual data fetching logic
      // const fetchedArticleSuggestion = articleFuse.search(query);
      // const fetchedVideoSuggestion = videoFuse.search(query);

      const res = await axios.get(`/api/search?query=${query}`);

      setCategorizedSuggestions(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle suggestion selection
  const handleSuggestionClick = (suggestion: string) => {
    setSearchQuery("");
    setSuggestions([]); // Clear suggestions when a suggestion is clicked
    toggle();
    setShowInput(false);
    setShowSuggestions(false);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      // Clicked outside the search bar, so clear the input and hide suggestions
      // setSearchQuery("");
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (showInput) {
      searchRef?.current?.focus();
    }
  }, [showInput]);

  return (
    <div className="relative z-50 flex items-center  gap-4" ref={inputRef}>
      <form action="" className="">
        <input
          type="text"
          placeholder="Search"
          className={`input input-sm  input-bordered  w-full rounded border-[#dddee6] bg-white text-base ${showInput ? "block" : "hidden"}`}
          onChange={handleInputChange}
          value={searchQuery}
          ref={searchRef}
        />
        <IoSearch
          className={"absolute right-4 top-1/2 translate-y-[-50%]"}
          onClick={() => setShowInput((prev) => !prev)}
        />
      </form>

      <ul
        className={`absolute right-0 top-9 flex w-full flex-col gap-4 divide-y overflow-auto rounded border bg-white p-4 pt-0 text-xs text-[#323239]  sm:w-[50vw] lg:w-[33vw] ${showSuggestions ? "flex" : "hidden"} max-h-[50vh]`}
      >
        <div className="">
          <p className="mt-3 font-bold">Videos</p>
          {categorizedSuggestions.videos.length > 0 && !!searchQuery ? (
            categorizedSuggestions.videos.map((suggestion, index) => (
              <li
                key={suggestion?.videoId}
                onClick={() => handleSuggestionClick(suggestion)}
                className=" mb-0 flex   items-center gap-2 pt-4"
              >
                <Link
                  href={`/shows/channels/${suggestion?.type}/#${suggestion?.videoId}`}
                  className="underline decoration-transparent transition duration-300 hover:decoration-black"
                  target={"_self"}
                  aria-label="search result"
                >
                  {suggestion?.title}
                </Link>
              </li>
            ))
          ) : (
            <li className=" flex   items-center gap-2 pt-4">
              <p className="">No results found</p>
            </li>
          )}
        </div>
        <div className="">
          <p className="mt-3 font-bold">Articles</p>
          {categorizedSuggestions.articles.length > 0 && !!searchQuery ? (
            categorizedSuggestions.articles.map((suggestion, index) => (
              <li
                key={suggestion?.slug}
                onClick={() => handleSuggestionClick(suggestion)}
                className=" mb-0 flex   items-center gap-2 pt-4"
              >
                <Link
                  href={`/posts/${suggestion?.slug}/`}
                  className="underline decoration-transparent transition duration-300 hover:decoration-black"
                  target={"_self"}
                >
                  {suggestion?.title}
                </Link>
              </li>
            ))
          ) : (
            <li className=" flex   items-center gap-2 pt-4">
              <p className="">No results found</p>
            </li>
          )}
        </div>
      </ul>
    </div>
  );
}

export default SearchBar;
